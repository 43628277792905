<template>
  <div v-if="platformPermissionsLoaded && checkPermission('loyalty.insights.employeespoints') && ['explorer', 'business', 'enterprise'].includes(productLicenses.loyalty_license_tag)">
    <CRow class="insights">
      <EmployeesPoints :cols="6"/> 

      <CCol cols="6" xl="6" lg="6" md="6" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Average_points_per_employee2')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>           
            <PointsCount v-else
                         mode="with_description"
                         :points="(parseInt(insightsData.organization_points.personal_loyalty_points) > 0 ? (parseInt(insightsData.organization_points.personal_loyalty_points) / insightsData.headcount.headcount_total).toFixed(0) : 0)"
                         :pointsTitle="$t('By') + ' ' + insightsData.headcount.headcount_total + ' ' + $t('insights.employees')"
                         :environmentTag="environmentTag">
            </PointsCount>
          </CCardBody>
        </CCard>        
      </CCol>
    </CRow>
    
    <CRow class="insights">
      <CCol col="12" xl="12">
        <CCard class="insights mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">              
                {{ $t('insights.Employee_points2') }}
              </CCol>
              <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
                <div class="mt-1 mt-xl-0 d-inline-block align-top">
                  <CButton class="ml-2 link" color="primary" @click="checkExportOptionWithinLicense('csv')">
                    <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_csv') }}</span>
                  </CButton>                
                  <CButton class="ml-2 link" color="primary" @click="checkExportOptionWithinLicense('json');">
                    <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_json') }}</span>              
                  </CButton>
                </div>               
              </CCol>  
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">
            <CRow>
              <CCol cols="12" lg="12" class="pb-0 pt-0">
                <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
                  <loadingSpinner mode="auto" :content="null"/>
                </div>
                <b-table v-else class="data_table" :data="insightsData.employees_points" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition" :default-sort-direction="defaultSortDirection" :sort-icon="sortIcon" :sort-icon-size="sortIconSize" default-sort="Department/Location">
                  <template slot-scope="props">
                    <b-table-column field="name" :label="$t('common.Employee')" :searchable="searchEnabled" :sortable="true" width="50%">                      
                      <div class="d-flex align-items-center">
                        <userProfilePopover ref="userProfilePopover"
                                            mode="user" 
                                            :userName="props.row.name"
                                            :userProfileImage="props.row.profile_image" 
                                            :userIdExternal="props.row.user_id_external"
                                            :senderIdExternal="null"
                                            popoverPlacement="right"
                                            class="mr-2">
                        </userProfilePopover>
                        <div>
                          <span>{{props.row.name}}</span>
                          <span class="d-block mt-1 meta">{{props.row.team_name}} - {{props.row.department_name}}</span>
                        </div>                        
                      </div>                      
                    </b-table-column>                   
                    <b-table-column field="personal_loyalty_points" :label="$t('common.Coins')" :searchable="false" :sortable="true" width="50%">
                      <div class="d-flex align-items-center credits">
                        <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                        <span>{{props.row.personal_loyalty_points}} {{props.row.personal_loyalty_points !== 1 ? $t('common.coins') : $t('common.coin')}}</span>
                      </div>                      
                    </b-table-column>
                    <!-- <b-table-column field="personal_loyalty_amount" :label="$t('common.Amount')" :searchable="false" :sortable="false" width="10%">
                      <span>&euro; {{props.row.personal_loyalty_amount}}</span>
                    </b-table-column> -->              
                  </template>
                  <template slot="empty">
                    <div class="p-2 text-center">
                      <span>{{ $t('insights.Statistics_not_found') }}</span>
                    </div>                
                  </template>                
                </b-table>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';

import EmployeesPoints from '@/components/dashboard/loyalty/EmployeesPoints.vue';
import Count from '@/components/insights/common/Count.vue';
import PointsCount from '@/components/insights/loyalty/PointsCount.vue';

export default {
  name: 'EmployeePoints',
  components: {
    loadingSpinner,
    noPermission,
    userProfilePopover,
    EmployeesPoints,
    Count,
    PointsCount
  },
  data: () => {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      platformPermissions: [],
      platformPermissionsLoaded: false, 
      productLicenses: {
        loyalty_licence_tag: null
      },           
      insightsData: [],
      loadingInsightsData: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: false,
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: true
    }
  },
  methods: {
    checkExportOptionWithinLicense(exportFormat) {
      let licenseTag = this.productLicenses.loyalty_license_tag;
      let licenseName = this.productLicenses.loyalty_license_name;
    
      if(['business', 'enterprise'].includes(licenseTag)) {        
        this.exportData(exportFormat);
      } else {
        this.openFeatureUnavailableModal({ license_name: licenseName });
      }
    },    
    getInsightsData() {
      // Start the loader
      this.loadingInsightsData = true;
      // Get the insights data
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/points/employees')
      .then(res => {
        // Set the insightsData
        this.insightsData = res.data.data;
        // Stop the loader
        this.loadingInsightsData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    exportData(outputFormat){
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/points/employees/export/' + outputFormat)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
        if (!window.navigator.msSaveOrOpenBlob){
          // Blob navigator
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.URL.createObjectURL(new Blob([outputData]));  
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.URL.createObjectURL(new Blob([outputData]));
          }
          // Create a new 'a' element
          const link = document.createElement('a');
          // Set the generated URL as href of the new element
          link.href = url;
          // Set the download attribute based on the output format
          if(outputFormat === 'csv') {
            link.setAttribute('download', 'insights-user-points.csv');
          } else if(outputFormat === 'json') {
            link.setAttribute('download', 'insights-user-points.json');
          }
          // Append the link to the body
          document.body.appendChild(link);
          // Click the link
          link.click();
        } else {
          // Blob for Explorer 11
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]),"insights-audience-login.csv");
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]),"insights-audience-login.json");
          }
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }       
  },
  async mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.productLicenses = await this.fetchProduuctLicenseData();

    this.getPlatformPermissions();
    this.getInsightsData();

    this.$bus.$on('filter_updated', (e) => {
      this.getUserPoints();
    });    
  },
  beforeDestroy() {
    this.$bus.$off('filter_updated');
  }  
}
</script>
